import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import Typography from "@material-ui/core/Typography";
import PublishIcon from '@material-ui/icons/Publish';
import { Grid } from "@material-ui/core";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';


export default function WelcomeDialog({ openWelcomeDialog, setOpenWelcomeDialog, setOpenImportDataDialog}) {
    const {t} = useTranslation();

    const handleClose = () => {
        setOpenWelcomeDialog(false);
    }; 
    const handleImportData = () => {
        setOpenWelcomeDialog(false);
        setOpenImportDataDialog(true);
    };

    return (
        <Dialog open={openWelcomeDialog} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title" justify="center"> {t("WelcomeDialog.Title")}</DialogTitle>
            <DialogContent>
                    <Typography >
                        {t("WelcomeDialog.subTitle")}
                    </Typography>
                    <Typography gutterBottom={true}>
                        {t("WelcomeDialog.Content")}
                    </Typography>
                <Grid container justify="center" direction="row" spacing={2} alignItems="center">
                    <Grid item xs>
                        <Button variant="contained" color="primary" startIcon={<PublishIcon />} fullWidth  onClick={handleImportData}>
                            {t("ImportData")}
                        </Button>
                    </Grid>
                    <Grid item xs>
                        <Button  variant="contained" color="primary" onClick={handleClose} startIcon={<KeyboardArrowRightIcon/>} fullWidth >
                            {t("StartfromScratch")}
                        </Button>
                    </Grid>
                </Grid>


            </DialogContent>

        </Dialog>
    );
}