import React from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    formControl: {
        minWidth: 120,
    },

    IconButton: {
        alignItems: "right"
    }
}));

const languagesLevel =["LanguagesLevel.ElementaryProficiency", "LanguagesLevel.LimitedWorking", "LanguagesLevel.ProfessionalWorking", "LanguagesLevel.FullProfessional", "LanguagesLevel.NativeBilingual",];



export default function Language({ languages, setLanguage, index }) {
    const classes = useStyles();
    const {t} = useTranslation();

    const removeLanguage = (event) => {
        event.persist();
        let languagesCopy = [...languages];
        languagesCopy.splice(index, 1);
        setLanguage(languagesCopy);
    };

    const handleLanguagefluencyChange = event => {
        const newLevel = event.target.value;
        let languagesCopy = [...languages];
        languagesCopy[index].fluency = newLevel;
        setLanguage(languagesCopy);
    };

    const handleLanguageChange = event => {
        const newLanguage = event.target.value;
        let languagesCopy = [...languages];
        languagesCopy[index].language = newLanguage;
        setLanguage(languagesCopy);
    };

    return <Grid container spacing={3}>
        <Grid item xs={5} >
            <TextField
                id={languages[index].id + "-textField-lang"}
                name={languages[index].id}
                label={t("Languages")}
                defaultValue={languages[index].language}
                onChange={handleLanguageChange}
                fullWidth
            />
        </Grid>

        <Grid item xs={4}>
            <FormControl className={classes.formControl}>
                <InputLabel id="fluency-select-label">{t("Level")}</InputLabel>
                <Select
                    labelId="fluency-select-label"
                    id={languages[index].id + "-fluency-select"}
                    name={languages[index].id}
                    value={languages[index].fluency}
                    onChange={handleLanguagefluencyChange}
                >
                    {
                        languagesLevel.map(lvl => (
                        <MenuItem key={lvl} value={t(lvl)}>{t(lvl)}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Grid>

        <Grid item xs={2}>
            <IconButton aria-label="delete" id={index} onClick={removeLanguage} className={classes.IconButton}>
                <DeleteIcon />
            </IconButton>
        </Grid>
    </Grid>

}
