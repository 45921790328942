import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from "firebase";
import 'firebase/analytics';
import "./i18next"

const firebaseConfig = {
  apiKey: "AIzaSyA6SD0utuxxeERVCBKbx5ttpDQzW4XQlaE",
  authDomain: "resume-generator-ekkiden.firebaseapp.com",
  databaseURL: "https://resume-generator-ekkiden.firebaseio.com",
  projectId: "resume-generator-ekkiden",
  storageBucket: "resume-generator-ekkiden.appspot.com",
  messagingSenderId: "219356215076",
  appId: "1:219356215076:web:dae6ea523da77c5b93a5b1",
  measurementId: "G-NZ33J48E0B"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

ReactDOM.render(<Suspense fallback={(<div>Loading...</div>)}><App /></Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
