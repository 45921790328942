import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from 'react-i18next';
import { GrValidate } from "react-icons/gr";
import InputAdornment from '@material-ui/core/InputAdornment';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#d2593e",
			main: "#9b2915",
			dark: "#660000",
			contrastText: "#fff",
		},
	},
});

const TextFieldComponent = (props) => {
	return <TextField {...props} disabled={true} />
}
const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(4),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},

	selectEmpty: {
		marginTop: theme.spacing(2),
	},

	dropdownOpen: {
		transition: theme.transitions.create(["transform"], {
			duration: theme.transitions.duration.short
		}),
		marginBottom: theme.spacing(-5),
		transform: "rotate(-180deg)"
	},
	dropdownClosed: {
		transition: theme.transitions.create(["transform"], {
			duration: theme.transitions.duration.short
		}),
		marginBottom: theme.spacing(-5),
		transform: "rotate(0)"
	}
}));

export default function Certification({ awards, setAward, index }) {

	const { t } = useTranslation();

	const [expanded, setExpanded] = useState(awards.length - 1 === index); //Expand if its last 

	const handleExpand = () => {
		setExpanded((prev) => !prev);
	};

	const removeCertification = (event) => {
		let awardsCopy = [...awards];
		awardsCopy.splice(index, 1);
		setAward(awardsCopy);
	};

	const handleChange = event => {
		const attributeName = event.target.id
			? event.target.id
			: event.target.name;
		const newValue = event.target.value;
		let awardsCopy = [...awards];
		awardsCopy[index][attributeName] = newValue;
		setAward(awardsCopy);
	};

	const handleDateChange = (date) => {
		let awardsCopy = [...awards];
		awardsCopy[index]["date"] = date;
		setAward(awardsCopy);
	};



	const classes = useStyles();
	return (
		<Paper className={classes.paper}>
			<Grid container spacing={2}  >


				<Grid item xs={10}>
					<TextField
						id={"title"}
						name="title"
						label={t("CertificationName")}
						defaultValue={awards[index].title}
						onChange={handleChange}
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<GrValidate />
								</InputAdornment>
							),
						}}
					/>

				</Grid>
			</Grid>
			<Collapse in={expanded}>
				<Grid container spacing={2}  >


					<Grid item xs={6} >
						<TextField
							id={"awarder"}
							onChange={handleChange}
							name="awarder"
							label={t("Organisation")}
							defaultValue={awards[index].awarder}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<BusinessOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>





					<Grid item xs={12}>
						<MuiPickersUtilsProvider spacing={4} utils={DateFnsUtils}>
							<Grid container justify="space-around">
								<KeyboardDatePicker
									views={["year", "month"]}
									variant="normal"
									format="MMMM yyyy"
									id={"date"}
									key="date"
									openTo="year"
									label={t("ObtentionDate")}
									value={awards[index].date}
									onChange={handleDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}

									TextFieldComponent={TextFieldComponent}

								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12}>
						<TextField

							id={"summary"}
							name="summary"
							label={t("Description")}
							defaultValue={awards[index].summary}
							onChange={handleChange}
							multiline
							rowsMax="3"
							fullWidth
							variant="outlined"
						/>
					</Grid>

					<Grid item xs container justify="flex-start" alignContent="flex-start" alignItems="flex-start">
						<ThemeProvider theme={theme}>

							<Button
								variant="contained"
								color="secondary"
								size="large"
								startIcon={<DeleteIcon />}
								onClick={removeCertification}
							>
								{t("DeleteCertification")}
							</Button>
						</ThemeProvider>
					</Grid>


				</Grid>

			</Collapse>
			<IconButton color="primary" aria-label="expand" onClick={handleExpand}
				className={expanded
					? classes.dropdownOpen
					: classes.dropdownClosed
				}>
				<ExpandMoreIcon fontSize="small" />
			</IconButton>
		</Paper>
	);
}
