import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import countries from "../utils/countries.js";
import { IoMdSchool } from "react-icons/io";
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FaSchool } from "react-icons/fa";


const TextFieldComponent = (props) => {
	return <TextField {...props} disabled={true} />
}

const theme = createMuiTheme({
	palette: {
		primary: {
			light: "#d2593e",
			main: "#9b2915",
			dark: "#660000",
			contrastText: "#fff",
		},
	},
});

const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(4),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},

	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},

	diplomaTextField: {
		marginTop: theme.spacing(2),
	},

	dropdownOpen: {
		transition: theme.transitions.create(["transform"], {
			duration: theme.transitions.duration.short
		}),
		marginBottom: theme.spacing(-5),
		transform: "rotate(-180deg)"
	},
	dropdownClosed: {
		transition: theme.transitions.create(["transform"], {
			duration: theme.transitions.duration.short
		}),
		marginBottom: theme.spacing(-5),
		transform: "rotate(0)"
	}
}));

export default function Education({ education, setEducation, index }) {

	const [expanded, setExpanded] = useState(true); //Expand if its last 
	const { t } = useTranslation();

	const handleExpand = () => {
		setExpanded((prev) => !prev);
	};

	const removeEducation = (event) => {
		let educationCopy = [...education];
		educationCopy.splice(index, 1);
		setEducation(educationCopy);
	};

	const handleChange = (attributeName, newValue) => {
		let edusCopy = [...education];
		edusCopy[index][attributeName] = newValue;
		setEducation(edusCopy);
	};

	const handleFromDateChange = (date) => {
		let edusCopy = [...education];
		edusCopy[index]["startDate"] = date;
		edusCopy[index]["endDate"] = date;
		setEducation(edusCopy);
	};

	const handleToDateChange = (date) => {
		let edusCopy = [...education];
		edusCopy[index]["endDate"] = date;
		setEducation(edusCopy);
	};

	const minDate = new Date("1990-01-01");
	const maxDate = new Date();
	maxDate.setYear(maxDate.getFullYear()+2);



	const classes = useStyles();
	return (
		<Paper className={classes.paper}>
			<Grid container spacing={2}  >
				<Grid item xs={10}  >
					<TextField
						id={education[index].id + "-institution"}
						onChange={(event) => handleChange("institution", event.target.value)}
						name="institution"
						label={t("SchoolName")}
						defaultValue={education[index].institution}
						required
						fullWidth
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<FaSchool />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
			</Grid>
			<Collapse in={expanded}>
				<Grid container spacing={2}  >

					<Grid item xs={10} >
						<TextField

							id={education[index].id + "-area"}
							name="area"
							label={t("DiplomaName")}
							className={classes.diplomaTextField}
							defaultValue={education[index].area}
							onChange={(event) => handleChange("area", event.target.value)}
							required
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<IoMdSchool />
									</InputAdornment>
								),
							}}

						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField

							id={education[index].id + "-city"}
							name="city"
							label={t("City")}
							defaultValue={education[index].city}
							onChange={(event) => handleChange("city", event.target.value)}
							fullWidth
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PlaceOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>

						<Autocomplete
							id={education[index].id + "-country"}
							name="country"
							options={countries.map((country) => country.label)}
							autoHighlight
							onChange={(event, value) => handleChange("country", value)}
							value={education[index].country}
							renderInput={(params) => (
								<TextField
									{...params}
									label={t("Country")}
									autoComplete="country-name"
									type="text"


									inputProps={{

										...params.inputProps,
										autoComplete: "new-password", // disable autocomplete and autofill
									}}
								/>
							)}
						/>
					</Grid>


					<Grid item xs={12}>
						<MuiPickersUtilsProvider spacing={4} utils={DateFnsUtils}>
							<Grid container justify="space-around">
								<KeyboardDatePicker
									views={["year", "month"]}
									variant="normal"
									format="MMMM yyyy"
									id={education[index].id + "-fromdate"}
									key="fromdate"
									minDate={minDate}
									maxDate={maxDate}
									openTo="year"
									label={t("StartDate")}
									value={education[index].startDate}
									onChange={handleFromDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
									TextFieldComponent={TextFieldComponent}

								/>

								<KeyboardDatePicker
									views={["year", "month"]}
									variant="normal"
									format="MMMM yyyy"
									id={education[index].id + "-endDate"}
									key="endDate"
									label={t("EndDate")}
									minDate={minDate}
									maxDate={maxDate}
									openTo="year"
									value={education[index].endDate}
									onChange={handleToDateChange}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
									TextFieldComponent={TextFieldComponent}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
					</Grid>
					<Grid item xs={12}>

						<Autocomplete
							id={education[index].id + "-courses"}
							name="courses"
							multiple
							className={classes.autocomplete}
							value={education[index].courses}
							freeSolo
							options={[]}
							onChange={(event, values) => handleChange("courses", values)}
							renderInput={(params) => (
								<TextField {...params} variant="outlined" label={t("Courses")} helperText={t("PressEnter")} placeholder={t("TypeHere")} />
							)}
						/>

					</Grid>

					<Grid item xs container justify="flex-start" alignContent="flex-start" alignItems="flex-start">
						<ThemeProvider theme={theme}>

							<Button
								variant="contained"
								color="secondary"
								size="large"
								startIcon={<DeleteIcon />}
								onClick={removeEducation}
							>
								{t("DeleteFormation")}
							</Button>
						</ThemeProvider>
					</Grid>
				</Grid>
			</Collapse>
			<IconButton color="primary" aria-label="expand" onClick={handleExpand}
				className={expanded
					? classes.dropdownOpen
					: classes.dropdownClosed
				}>
				<ExpandMoreIcon fontSize="small" />
			</IconButton>
		</Paper>
	);
}
