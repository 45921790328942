import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import HeaderForm from "./components/HeaderForm";
import EducationForm from "./components/EducationForm";
import ExperienceForm from "./components/ExperienceForm";
import SkillForm from "./components/SkillForm";
import FinalizeForm from "./components/FinalizeForm";
import CertificationForm from "./components/CertificationForm";
import nextId from "react-id-generator";
import WordDocument from "./components/WordDocument";
import Ekkibot from "./components/Ekkibot";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import useWindowDimensions from "./utils/useWindowDimensions"
import TranslateIcon from '@material-ui/icons/Translate';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import ImportDataDialog from "./components/ImportDataDialog";
import WelcomeDialog from "./components/WelcomeDialog";
import PublishIcon from '@material-ui/icons/Publish';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import GoogleDocsViewer from 'react-google-docs-viewer';
function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" className={useStyles().copyright}>
            {"Copyright © "}
            <Link color="inherit" href="https://ekkiden.com/" target="_blank" rel="noopener noreferrer">
                Ekkiden
      </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}

const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#6e9eff",
            main: "#2d70d7",
            dark: "#0046a5",
            contrastText: "#fff",
        },
        secondary: {
            light: "#FFF",
            main: "#FFF",
            dark: "#cccccc",
            contrastText: "#141D38",
        },
        textSecondary:'#cccccc',
        errors: {
            light: "#d2593e",
            main: "#9b2915",
            dark: "#660000",
            contrastText: "#fff",
        },
        background: {
            paper: '#fff',
            default: "#F6FAFF"
          },
    },


});

const useStyles = makeStyles(theme => ({

    appBar: {
        position: "relative",
    },
    logo: {
        maxHeight: 42,
    },
    copyright: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    layout: {
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        padding: theme.spacing(2),
    },

    stepper: {
        padding: 0,

    },
    stepButton: {
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0,
    },
    buttons: {
        display: "flex",
        justifyContent: "flex-end",
    },
    button: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

    chatBot: {
        bottom: 0,
        right: 0,
        position: "absolute",
        borderWidth: 1,
        alignSelf: "flex-end"
    },
}));

export default function App() {
    const classes = useStyles();
    const { height, width } = useWindowDimensions();

    // Start Languages
    const { t, i18n } = useTranslation();
   
    const appLanguages = [
        {
            name: "English",
            code: "en"
        },
        {
            name: "Français",
            code: "fr"
        },
        {
            name: "Español",
            code: "es"
        },
        {
            name: "Deutsch",
            code: "de"
        },
        {
            name: "Italiano",
            code: "it"
        },
    ];
    const firstAppLang = appLanguages.filter((appLang) => appLang.code ===  i18n.language);
    const [appLanguage, setAppLanguage] = useState(firstAppLang[0]); //Setting up the browser language
    

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const openMenu = Boolean(menuAnchorEl);

    const handleOpenMenu = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleLangChange = (lang) => {
        setAppLanguage(lang);
        i18n.changeLanguage(lang.code);
        setMenuAnchorEl(null);
    };

   
    // END Languages

    const [openWelcomeDialog, setOpenWelcomeDialog] = useState(true);
    const [openImportDataDialog, setOpenImportDataDialog] = useState(false);
    const [showSuccessDialog, setShowSuccessDialog] = useState(false);


    const handleOpenImportDataDialog = () => {
        setOpenImportDataDialog(true);
    };



    const [docURL, setdocURL] = useState(""); //URL to a doc generated within the Browser   
    const refreshPreview = () => {
        
        const word = new WordDocument(JSON_resume, "ekkiden-template-V2.docx");  //TODO Becareful to use the appropriate template
        word.setDocURL(setdocURL);
    };




    //*** START OF STEPPER */
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({}); //Completed steps


    const handleStep = step => () => {
        const newCompleted = completed;
        newCompleted[step] = false;
        setCompleted(newCompleted);
        setActiveStep(step);
    };

    const handleNext = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep(activeStep + 1);
        window.scrollTo({
            top: 0,
        });
    };
    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep - 1] = false;
        setCompleted(newCompleted);
        setActiveStep(activeStep - 1);
        window.scrollTo({
            top: 0,
        });
    };

    //*** END OF STEPPER */

    const [basics, setBasics] = useState({
        name: "",
        firstName: "",
        lastName: "",
        label: "", //Resume Title
        summary: "", //Global Summary / Profile
        email: "",
        phone: "",
        yearsOfExperience: "",
        location: {
            "address": "",
            "postalCode": "",
            "city": "",
            "countryCode": "",
            "region": ""
        },
        profiles: [
            {
                "network": "website",
                "username": "",
                "url": ""
            }
        ],
    });

    const [education, setEducation] = useState([
        {
            id: nextId("educ-id-"),
            institution: "",
            area: " ",
            city: "",
            country: "",
            startDate: new Date("2010-09-01"),
            endDate: new Date("2012-09-01"),
            courses:[]
        },
    ]);

    const [awards, setAward] = useState([]);

    const [work, setWork] = useState([
        {
            id: nextId("exp-id-"),
            company: "",
            position: "",
            city: "",
            country: "",
            startDate: new Date("2015-09-01"),
            endDate: new Date("2017-09-01"),
            summary: "",
            context: "",
            achievements: "",
            references: [{
                name : "",
                position: "",
                email: "",
                phone: ""            
            }],
            environment: [],
        },
        
    ]);

    const [languages, setLanguage] = useState([
        {
            id: nextId("lang-id-"),
            language: "English",
            fluency: "",
        },
    ]);

    const [skills, setSkill] = useState({
        "Technical Skills": [],
        "Functional Skills": [],
        "Business Sectors": [],
    });

    const JSON_resume = { basics: basics, education: education, work: work, languages: languages, skills: skills, awards: awards };
    //ADD the fields name in the template
    JSON_resume.TEMPLATE_STRING = {
        "t_mobile": t("Template_docx.Mobile"),
        "t_email": t("Email"),
        "t_profil": t("Template_docx.PROFIL"),
        "t_education": t("Template_docx.EDUCATION"),
        "t_workExp": t("Template_docx.WORK_EXPERIENCE"),
        "t_responsibilities": t("Template_docx.RESPONSIBILITIES"),
        "t_context": t("Template_docx.CONTEXT"),
        "t_achievements": t("Achievements"),
        "t_lang": t("Template_docx.LANGUAGES"),
        "t_techSki": t("Template_docx.TECH_SKILLS"),
        "t_funcSki": t("Template_docx.FUNC_SKILLS"),
        "t_busSectors": t("Template_docx.BUSINESS_SECTORS"),
        "t_certi": t("Template_docx.CERTIFICATIONS"),
        "t_interests": t("Template_docx.INTERESTS"),
        "t_techEnv": t("Template_docx.TECH_ENV"),
        "t_expYears": t("Template_docx.years_of_experience"),
        "t_references": t("References")
    };
    
    const JSON_setters = { setBasics: setBasics, setEducation: setEducation, setWork: setWork, setLanguage: setLanguage, setSkill: setSkill, setAward: setAward };

    const Steps = [
        {
            name: t("Header"),
            content: function () { return <HeaderForm basics={basics} setBasics={setBasics} appLanguage={appLanguage} />; }
        },
        {
            name: t("Education"),
            content: function () { return <EducationForm education={education} setEducation={setEducation} />; }
        },
        {
            name: t("Certifications"),
            content: function () { return <CertificationForm awards={awards} setAward={setAward} />; }
        },
        {
            name: t("Experiences"),
            content: function () { return <ExperienceForm work={work} setWork={setWork} />; }
        },
        {
            name: t("Skills"),
            content: function () { return <SkillForm languages={languages} setLanguage={setLanguage} skills={skills} setSkill={setSkill} />; }
        },
        {
            name: t("Finalize"),
            content: function () { return <FinalizeForm JSON_resume={JSON_resume} />; }
        },
    ]

    const bot_data = { Steps: Steps };

    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <CssBaseline />
                <AppBar position="absolute" color="secondary" className={classes.appBar}>
                    <Toolbar>
                        <Grid
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid container item xs direction="row" alignItems="center" justify="flex-start">
                                <a href="https://www.ekkiden.com/" target="_blank" rel="noopener noreferrer">
                                    <img src={require("./img/slogan-ekkiden.png")} alt="Ekkiden" className={classes.logo} />
                                </a>
                                <Typography variant="h6" >
                                    {(width > 570)
                                        ? "- " + t("MakeYourResume")
                                        : ""}

                                </Typography>
                            </Grid>

                            <Grid item xs={1} container justify="center" >
                                <Typography variant="h4" >
                                    {Steps[activeStep].name}
                                </Typography>
                            </Grid>


                            <Grid item xs container alignItems="center" justify="flex-end" direction="row" >
                                <Button variant="contained" color="primary" onClick={handleOpenImportDataDialog} startIcon={<PublishIcon />}>
                                    {t("ImportData")}
                                </Button>
                                <ImportDataDialog JSON_setters={JSON_setters} openImportDataDialog={openImportDataDialog} setOpenImportDataDialog={setOpenImportDataDialog} setShowSuccessDialog={setShowSuccessDialog} />
                                <Button
                                    startIcon={<TranslateIcon fontSize="large" />}
                                    endIcon={<ExpandMoreIcon />}
                                    color="primary" aria-controls="simple-menu" aria-haspopup="true" onClick={handleOpenMenu}>
                                    {appLanguage.name.toUpperCase()}
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    keepMounted
                                    open={openMenu}
                                    onClose={() => setMenuAnchorEl(null)}
                                    anchorEl={menuAnchorEl}
                                >
                                    {appLanguages.map((lang) => (
                                        <MenuItem key={lang.code} onClick={() => handleLangChange(lang)}>{lang.name}</MenuItem>
                                    ))}
                                </Menu>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>

                <Dialog
                    open={showSuccessDialog}
                    onClose={() => { setShowSuccessDialog(false) }}

                >
                    <DialogTitle id="alert-dialog-title">{t("SucessDialog.Title")}</DialogTitle>
                    <DialogContent>
                        <Grid container justify="center" direction="row" spacing={2} alignItems="center" >
                            <Grid item xs={1}>
                                <CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
                            </Grid>
                            <Grid item xs>
                                <Typography>{t("SucessDialog.Content")}</Typography>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => { setShowSuccessDialog(false) }} color="primary" autoFocus variant="text">
                            {t("Close")}
                        </Button>
                    </DialogActions>
                </Dialog>

                
                <WelcomeDialog openWelcomeDialog={openWelcomeDialog} setOpenWelcomeDialog={setOpenWelcomeDialog} setOpenImportDataDialog={setOpenImportDataDialog} />
                {openImportDataDialog || openWelcomeDialog || showSuccessDialog
                    ? <div></div>
                    : <main className={classes.layout}>
                        <Grid container justify="center" direction="row" spacing={3} wrap="wrap" alignItems="flex-start">
                            <Grid container key="1" item sm={12} md={6} direction="column" >
                                <Grid item xs>
                                    <Paper className={classes.paper} >
                                        <Stepper activeStep={activeStep} className={classes.stepper} alternativeLabel nonLinear  >
                                            {
                                                Steps.map((step, index) => (
                                                    <Step key={step.name}  >
                                                        <StepButton disableRipple color='secondary' className={classes.stepButton} onClick={handleStep(index)} completed={completed[index]}>{
                                                            (width > 570)
                                                                ? Steps[index].name
                                                                : ""}</StepButton>
                                                    </Step>
                                                ))}
                                        </Stepper>
                                    </Paper>
                                </Grid>
                                <Grid item xs>
                                    <React.Fragment>
                                        {Steps[activeStep].content()}
                                        <div className={classes.buttons}>
                                            {activeStep !== 0 && (
                                                <Button onClick={handleBack} className={classes.button}>
                                                    {t("Back")}
                                                </Button>
                                            )}
                                            {activeStep < Steps.length - 1 && (
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={handleNext}
                                                    className={classes.button}>
                                                    {t("Next")}
                                                </Button>)
                                            }

                                        </div>
                                    </React.Fragment>
                                </Grid>
                            </Grid>

                            <Grid key="2" item sm={12} md={6}>

                                <Button
                                    align="center"
                                    variant="contained"
                                    color="primary"
                                    onClick={refreshPreview}
                                    className={classes.button}>
                                    {t("Refresh")}
                                </Button>

                                {docURL !== ""
                                    ? (<GoogleDocsViewer
                                        width={width > 959 ? width/2 -32 : width - 64 }
                                        height={height}
                                        fileUrl={docURL}
                                      />)
                                    : ""
                                }
                            </Grid>
                        </Grid>
                        <Copyright />
                    </main>
                }



                <Ekkibot classname={classes.chatBot} bot_data={bot_data} activeStep={activeStep} />
            </React.Fragment>

        </ThemeProvider>

    );
}
