import React from "react";
import Certification from "./Certification";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import nextId from "react-id-generator";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	list: {
		padding: 0,
		margin: 0
	},

	listItem: {
		paddingLeft: 1,
		paddingRight: 0,
		paddingTop: 1,
		paddingBottom: theme.spacing(2),
	},

	button: {
		marginTop: theme.spacing(0),
	},
}));


export default function CertificationForm({ awards, setAward }) {

	const classes = useStyles();
	const {t} = useTranslation();

	const onDrop = ({ removedIndex, addedIndex }) => {
		const newCertif = arrayMove(awards, removedIndex, addedIndex)
		setAward(newCertif);
	};

	const addCertification = () => {
		setAward(
			[
				...awards,
				{
					id: nextId("certif-id-"),
					name: "",
					awarder: "",
					date: new Date("2000-09-01"),
					summary: "",
				},

			]
		)
	};

	return (
		<List className={classes.list}>
			<Container lockAxis="y" onDrop={onDrop} >
				{awards.map((certification, index) => (
					<Draggable key={certification.id} >
						<ListItem className={classes.listItem}>
							<Certification awards={awards} setAward={setAward} index={index} />
							<IconButton disableRipple color="primary"><DragHandleIcon color="primary" /></IconButton>
						</ListItem>
					</Draggable>
				))}
			</Container>

			<Button
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={addCertification}
			>
				{t("AddCertification")}
			</Button>
		</List>
	);
}

