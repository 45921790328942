import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import WordDocument from "./WordDocument"
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { green } from '@material-ui/core/colors';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';

import FormLabel from '@material-ui/core/FormLabel';

import firebase from "firebase";
import 'firebase/analytics';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(4),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},

	formControl: {
		minWidth: 180,
	},

	selectEmpty: {
		marginTop: theme.spacing(2),
	},

	language: {
		margin: theme.spacing(2),

	},
	inputLabel: {
		color: theme.palette.primary.main,
	},
	checkGroup: {
		marginLeft: theme.spacing(10),
	}

}));

export default function FinalizeForm({ JSON_resume }) {
	const basics = JSON_resume.basics;

	const classes = useStyles();
	const { t } = useTranslation();

	const [openSubmittedDialog, setOpenSubmittedDialog] = useState(false);
	const [officeList, setOfficeList] = useState([]);
	const [officeToSendIndex, setOfficeToSendIndex] = useState('');
	const [checkState, setCheckState] = React.useState({
		checkedA: false,
		checkedB: false,
		checkedC: false,
	});
	const [formIsValid, setFormIsValid] = React.useState(true);


	const GreenCheckbox = withStyles({
		root: {
			color: green[400],
			'&$checked': {
				color: green[600],
			},
		},
		checked: {},
	})((props) => <Checkbox color="default" {...props} />);

	useEffect(() => {
		const getOfficeList = () => {
			const offices = [];
			const db = firebase.firestore();
			db.collection("ekkiden_offices")
				.get()
				.then(function (querySnapshot) {
					querySnapshot.forEach(function (doc) {
						// doc.data() is never undefined for query doc snapshots
						let office = doc.data();
						offices.push(office);
					});
					setOfficeList(offices);
					setOfficeToSendIndex(0);
				})
				.catch(function (error) {
					console.log("Error getting documents: ", error);
				});
		}
		getOfficeList();
	}, []);

	useEffect(() => {
		const isValid = isFormValid();
		if (isValid)
			setFormIsValid(isValid);
	}, [checkState]);



	const handleChecksChange = (event) => {
		setCheckState({ ...checkState, [event.target.name]: event.target.checked });
	};

	const createAndDonwloadWord = () => {
		const word = new WordDocument(JSON_resume, "ekkiden-template-V2.docx"); //TODO Becareful to use the appropriate template
		word.download();
	};

	const isFormValid = () => {
		const valid = Object.values(checkState).every((check) => check);
		return (valid);
	}

	const createAndSend = () => {

		const isValid = isFormValid();
		setFormIsValid(isValid);

		if (isValid) {
			console.log('Sending emails to: ', officeList[officeToSendIndex]);
			//EDIT also the one in refresh Preview
			const word = new WordDocument(JSON_resume, "ekkiden-template-V2.docx"); //TODO Becareful to use the appropriate template
			word.sendToEkkiden(officeList[officeToSendIndex].emails);
			firebase.analytics().logEvent("sent_cv_to_ekkiden");

			setOpenSubmittedDialog(true);
		}
	}

	const selectofficeToSendIndex = function (classes) {
		return (
			<Grid item container xs={12} justify="center">
				<Grid item xs={12} >
					<Typography>{t("ChooseOffice")}</Typography>
				</Grid>
				<Grid item xs={12} >
					<FormControl className={classes.formControl} required>
						<InputLabel id="label-office" className={classes.inputLabel} >{t("EkkidenOffice")}</InputLabel>
						<Select

							labelId="label-office"
							id="office-select"
							value={officeToSendIndex}
							onChange={(event) => {
								setOfficeToSendIndex(event.target.value);
							}}
							label='Ekkiden Office'
							required
						>
							{officeList.map((office, index) =>
								<MenuItem key={office.label} value={index}>{office.label}</MenuItem>)
							}
						</Select>
					</FormControl>
				</Grid>
			</Grid>

		);
	}





	return (
		<React.Fragment>
			<Paper className={classes.paper}>
				<Dialog
					open={openSubmittedDialog}
					onClose={() => { setOpenSubmittedDialog(false) }}

				>
					<DialogTitle id="alert-dialog-title">{t("SubmittedDialog.Title")}</DialogTitle>
					<DialogContent>
						<Grid container justify="center" direction="row" spacing={2} alignItems="center" >
							<Grid item xs={1}>
								<CheckCircleIcon fontSize="large" style={{ color: green[500] }} />
							</Grid>
							<Grid item xs>
								<Typography>{t("SubmittedDialog.Content")}</Typography>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => { setOpenSubmittedDialog(false) }} color="primary" autoFocus variant="text">
							{t("Close")}
						</Button>
					</DialogActions>
				</Dialog>

				<Grid container spacing={4} >
					<Grid item xs={12}>
						<Typography component="h1" variant="h4" align="center">
							{t("WellDone") + " " + basics.firstName + ", " + t("HereIsYourResume") + "!"}
						</Typography>
					</Grid>
					<Grid item xs={12} >
						<Button
							align="center"
							variant="contained"
							color="primary"
							onClick={createAndDonwloadWord}
						>
							{t("DownloadResume")}
						</Button>
					</Grid>
					<Grid item xs={12} >
						<FormControl required error={!formIsValid}>
							<FormLabel component="legend">{t('BeforeSubmitting')}</FormLabel>
							<FormGroup className={classes.checkGroup}>

								<FormControlLabel
									control={<GreenCheckbox
										checked={checkState.checkedA} onChange={handleChecksChange} name="checkedA" />}
									label={t("CheckList.checkedA")}
								/>
								<FormControlLabel
									control={<GreenCheckbox
										checked={checkState.checkedB} onChange={handleChecksChange} name="checkedB" />}
									label={t("CheckList.checkedB")}

								/>
								<FormControlLabel
									control={<GreenCheckbox
										checked={checkState.checkedC} onChange={handleChecksChange} name="checkedC" />}
									label={t("CheckList.checkedC")}
								/>
							</FormGroup>
							<FormHelperText>{formIsValid ? "" : t("CheckList.error")}</FormHelperText>
						</FormControl>


					</Grid>


					{selectofficeToSendIndex(classes)}


					<Grid item xs={12} >
						<Button
							align="center"
							variant="contained"
							color="primary"
							onClick={createAndSend}
						>
							{t("SendToEkkiden") + (officeList[officeToSendIndex] ? ' ' + officeList[officeToSendIndex].emojiFlag : '')}
						</Button>
					</Grid>


				</Grid>
			</Paper>
		</React.Fragment>

	);
}

