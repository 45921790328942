import React, { } from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";
import * as firebase from "firebase";
import isEmailValid from "../utils/isEmailValid"

const theme = {
	background: "#f5f8fb",
	headerBgColor: "#2d70d7",
	headerFontColor: "#fff",
	headerFontSize: "15px",
	botBubbleColor: "#0046a5",
	botFontColor: "#fff",
	userBubbleColor: "#fff",
	userFontColor: "#4a4a4a",
};

const uploadFeedback = (props) => {
	const steps = props.steps;
	const feedback = replaceUndefined({
		conversation: steps["getFeedback"].message,
		section: steps["getSection"].message,
		date: firebase.firestore.FieldValue.serverTimestamp(),
	});
	const db = firebase.firestore();
	db.collection("feedback").add(feedback);
	return "feedbackReceived";
}


const uploadRequest = (props) => {
	const steps = props.steps;
	const request = replaceUndefined({
		request: steps["getRequest"].message,
		name: steps["getName"].message,
		email: steps["getEmail"].message,
		date: firebase.firestore.FieldValue.serverTimestamp(),
	});
	const db = firebase.firestore();
	db.collection("request").add(request);
	return "requestReceived";
}

Object.filter = (obj, predicate) => 
    Object.assign(...Object.keys(obj)
                    .filter( key => predicate(obj[key]) )
                    .map( key => ({ [key]: obj[key] }) ) );

const uploadSurvey = (props) => {
	const steps = props.steps;
	const stepsSurvey = Object.filter(steps, step => step.id.toLowerCase().includes("survey"))
	const surveyData = Object.keys(stepsSurvey).map(key => {
			return replaceUndefined({id: stepsSurvey[key].id, message: stepsSurvey[key].message })
	});

	const conversationClean = [];
	let lastAdded = false;
	for (let i = 0; i < surveyData.length -1; i++) {
		const curr = surveyData[i];
		const next = surveyData[i+1];
		if (curr.id.substring(3, curr.id.length) === next.id.substring(3, curr.id.length) ){
			conversationClean.push(replaceUndefined({
				id: curr.id.substring(3, curr.id.length),
				question: (typeof curr.message !== "undefined") ? curr.message : curr.value,
				answer: (typeof next.message !== "undefined" ) ? next.message : next.value,
			}))
			lastAdded = (i+1 === surveyData.length -1);
			i++;
		}

		// just put the previous item
		else {
			conversationClean.push(replaceUndefined({
				id:curr.id,
				question: curr.message,
				answer: "",
			}))
		}
	}
	if (!lastAdded){
		const lastItem = surveyData[surveyData.length - 1];
		conversationClean.push(replaceUndefined({
		id:lastItem.id,
		question: lastItem.message,
		answer: "",
		}))
	}

	const survey = {
		conversation: conversationClean,
		date: firebase.firestore.FieldValue.serverTimestamp(),
	};
	const db = firebase.firestore();
	db.collection("answers_survey").add(survey);
	return "end";
}

const replaceUndefined = (obj, replace="") =>{
	for (let prop in obj) {
		if (obj[prop] === undefined) {
		  obj[prop] = replace;
		}
	}
	return obj;
}
export default function Ekkibot({ bot_data}) {
	const Steps = bot_data.Steps;
	return (
		<ThemeProvider theme={theme}>
			<ChatBot
				userDelay={200}
				headerTitle="Ekkibot"
				floating={true}
				steps={[
					{
						id: "1",
						message: "Hey good to see you, what do you want to do ?",
						trigger: "menu",
					},
					{
						id: "menu",
						options: [
							{ value: "feedback", label: "Give a feedback", trigger: "InitFeedback" },
							{ value: "survey", label: "Answer the survey", trigger: "InitSurvey" },
							{ value: "contact", label: "I need help", trigger: "InitRequest" },
						],
					},
					//Start Request
					{
						id: "InitRequest",
						message: "In order to handle your request we will need some information to handle your request.",
						trigger:"askName"
					},
					{
						id: "askName",
						message: "How should I call you ?",
						trigger: "getName",
					},
					{
						id: "getName",
						user: true,
						trigger: "askEmail",
					},
					{
						id: "askEmail",
						message: "Perfect {previousValue} ! Kindly tell me your email address so we can contact you back. I'll never give or sell your email address to anyone, and I promise not to send you unsolicited spam messages.",
						trigger: "getEmail",
					},
					{
						id: "getEmail",
						user: true,
						validator: (email) => {
							if (isEmailValid(email))
								return true;
							else
								return "The email you entered is not valid";
						  },
						trigger: "askRequest",
					},
					{
						id: "askRequest",
						message: "Now tell me how I can help :)",
						trigger: "getRequest",
					},
					{
						id: "getRequest",
						user: true,
						trigger: uploadRequest,
					},
					{
						id: "requestReceived",
						message: "Request Received, our team is on it, we will contact you soon, Do you want to do something else ?",
						trigger: "menu",
					},
					//END Request
					// START FEEDBACK
					{
						id: "InitFeedback",
						message: "On which section do you want to do your comment ?",
						trigger:"getSection"
					},
					{
						id: "getSection",
						options: Steps.map((step) => {
							return {value: step.name + " section", label: step.name, trigger: "askFeedback"};
						})
					},
					{
						id: "askFeedback",
						message: "Please write your feedback about the {previousValue}",
						trigger:"getFeedback"
					},

					{
						id: "getFeedback",
						user: true,
						trigger: uploadFeedback,
					},
					{
						id: "feedbackReceived",
						message: "Thank you for your feedback, do you want to do something else ?",
						trigger: "menu",
					},
					{
						id: "feedbackError",
						message: "We had an error sending your feedback, please try again.",
						trigger: "menu",
					},
					// END FEEDBACK
					//START SURVEY
					{
						id: "InitSurvey",
						message: "Let's fill this survey, it should last less than 3min.",
						trigger: "askSurvey1",
					},
					{
						id: "askSurvey1",
						message: "What tool were you using before to create a resume ?",
						trigger: "getSurvey1",
					},
					{
						id: "getSurvey1",
						user: true,
						trigger: "askSurvey2",
					},
					{
						id: "askSurvey2",
						message: "Compared to this App how difficult is it to use ?",
						trigger: "getSurvey2",
					},
					{
						id: "getSurvey2",
						user: true,
						trigger: "askSurvey3",
					},
					{
						id: "askSurvey3",
						message: "Will you start using this App to generate Portfolio ? Why ?",
						trigger: "getSurvey3",
					},
					{
						id: "getSurvey3",
						user: true,
						trigger: "askSurvey4",
					},
					{
						id: "askSurvey4",
						message: "Any idea on how we can improve this service ?",
						trigger: "getSurvey4",
					},
					{
						id: "getSurvey4",
						user: true,
						trigger: "askSurveyMore",
					},
					{
						id: "askSurveyMore",
						message: "Any last comment?",
						trigger: "getSurveyMore",
					},
					{
						id: "getSurveyMore",
						options:[{value: "yes", message: "Yes", label:"Yes", trigger: "askSurveyLast"},
								 {value: "no", message: "No", label: "No, I'm good" , trigger: uploadSurvey }]
					},
					{
						id: "askSurveyLast",
						message: "So tell me about it",
						trigger: "getSurveyLast",
					},
					{
						id: "getSurveyLast",
						user: true,
						trigger: uploadSurvey,
					},
					//END SURVEY

					{
						id: "end",
						message: "Thank you for your help, Have a nice day!",
						hideInput:true,
						end:true,
					},
				]}
			/>
		</ThemeProvider>



	);
}


