import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import * as firebase from "firebase";
import PizZipUtils from "../utils/pizzip-utils";
import axios from "axios";

function loadFile(url, callback) {
    PizZipUtils.getBinaryContent(url, callback);
}

const getFormatedData = (JSON_resume) => {
    const basics = JSON_resume.basics;
    const education = JSON_resume.education;
    const work = JSON_resume.work;
    const languages = JSON_resume.languages;
    const skills = JSON_resume.skills;
    const awards = JSON_resume.awards;
    const templateString = JSON_resume.TEMPLATE_STRING;
    const references = work.reduce(function  (a, b) {
        let companyRef = b.references
        .map((ref) => {
            ref.company = b.company;
            ref.country = b.country;
            ref.city = b.city;
            ref.hasCity = ref.city !== "" && ref.city !== undefined;
            ref.hasCountry = ref.country !== "" && ref.country !== undefined;
            ref.hasPhone = ref.phone.length > 4 && ref.phone !== undefined;
            ref.hasEmail = ref.email !== "" && ref.email !== undefined;
            return ref;
        })
        .filter(ref => ref.hasPhone || ref.hasEmail);
        return a.concat(companyRef);
       
            
    }, [] );
    const sectionToShow = {
        "hasYearsOfExp": basics.yearsOfExperience !== "",
        "hasEmail": basics.email !== "",
        "hasPhone": basics.phone.length > 4,
        "hasProfiles": basics.profiles.filter((profile) => profile.username !== "").length > 0,
        "hasAddress": basics.address !== "",
        "hasTechSkills": Object.keys(skills["Technical Skills"]).length > 0,
        "hasFuncskills": Object.keys(skills["Functional Skills"]).length > 0,
        "hasSectors": Object.keys(skills["Business Sectors"]).length > 0,
        "hasAwards": Object.keys(awards).length > 0,
        "hasRefs": Object.keys(references).length > 0,
        "hasLanguages": Object.keys(languages).length > 0,
    };
    //const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let data =
    {
        ...templateString, //Name of the different sections
        ...basics,
        address: basics.location.address,
        ...sectionToShow,
        education: education.map(function (education) {
            const edu = Object.assign({}, education);
            edu.startDate = ("0" + (edu.startDate.getMonth() + 1)).slice(-2) + "/"
                + edu.startDate.getFullYear();
            edu.endDate = (edu.endDate === -1)
                ? "Present"
                : ("0" + (edu.endDate.getMonth() + 1)).slice(-2) + "/"
                + edu.endDate.getFullYear();

            edu.hasCity = edu.city !== "" && edu.city !== undefined;
            edu.hasCountry = edu.country !== "" && edu.country !== undefined;
            return edu;
        }),
        references: references, //Map the references from each work
        work: work.map(function (experience) {
            const exp = Object.assign({}, experience);
            exp.startDate = ("0" + (exp.startDate.getMonth() + 1)).slice(-2) + "/"
                + exp.startDate.getFullYear();
            exp.endDate = (exp.endDate === -1)
                ? "Present"
                : ("0" + (exp.endDate.getMonth() + 1)).slice(-2)  + "/"
                + exp.endDate.getFullYear();
            exp.hasEnvironment = exp.environment.length > 0;
            exp.hasCity = exp.city !== "" && exp.city !== undefined;
            exp.hasCountry = exp.country !== "" && exp.country !== undefined;

            return exp;
        }),
    
        awards: awards.map(function (certification) {
            let certif = Object.assign({}, certification);
            certif.date = certif.date.getFullYear();
            return certif;
        }),
        languages: languages,
        techskills: skills["Technical Skills"],
        funcskills: skills["Functional Skills"],
        sectors: skills["Business Sectors"],

    };
    console.log("DATA ", data);
    console.log("DATA ", JSON.stringify(data));

    return data;
};

const getBlob = (doc) => {
    return doc.getZip().generate({
        type: "blob",
        mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });  //Output the document using Data-URI
};

const downloadFile = (blob, filename) => {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download = filename;
    a.click();
};

const createDoc = (zip, formated_data) => {
    let doc;
    try {
        doc = new Docxtemplater(zip, { linebreaks: true });

        console.log("Docx Read Successful");
    } catch (error) {
        // Catch compilation errors (errors caused by the compilation of the template : misplaced tags)
        console.log("cannot read the zip docx ", error);

    }
    try {
        doc.setData(formated_data);
        console.log("Data Added Successfuly");
    } catch (error) {
        console.log("Error while setting the data ", error);
    }


    try {
        // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
        doc.render();
    }
    catch (error) {
        // Catch rendering errors (errors relating to the rendering of the template : angularParser throws an error)
    }
    return doc;
};

const logFBEvent = (resume_date) => {
    const analytics = firebase.analytics();
    analytics.logEvent("donwload_resume");
};

const sendEmail = async (email, docURLProm, name, filename) => {
    const docURL = await docURLProm;

    const response = await axios.post(
        'https://europe-west1-resume-generator-ekkiden.cloudfunctions.net/sendMailOverHTTP',
        null,
        {
            params: {
                email: email,
                docURL: docURL,
                name: name,
                fileName: filename
            }
        },
    );
    console.log('Sending email to ', email);
    console.log(response.data)
}

class WordDocument {
    constructor(JSON_resume, templateName = "ekkiden-template-V2.docx") {
        this.JSON_resume = JSON_resume;
        this.templateName = templateName;
        this.filenameDownload = "Portfolio of Skills " + JSON_resume.basics.firstName + " - " + JSON_resume.basics.label + ".docx";
        this.filenameFireSave = JSON_resume.basics.lastName + "-" + JSON_resume.basics.firstName + "-" + JSON_resume.basics.label + ".docx";

    }

    async uploadToFirebase(blob, bucket, filename) {
        const destinationRef = firebase.storage().ref(bucket + "/" + filename);
        await destinationRef.put(blob);
        const docURL = await destinationRef.getDownloadURL();
        return docURL;
    }

    async saveData(JSON_resume_in) {
        const JSON_resume = Object.assign({}, JSON_resume_in);
        delete JSON_resume.TEMPLATE_STRING;
        const lastName = JSON_resume.basics.lastName;
        const firstName = JSON_resume.basics.firstName;
        const label = JSON_resume.basics.label;
        const data = {
            JSON_Resume: JSON_resume,
            date: firebase.firestore.FieldValue.serverTimestamp(),
        };
        const db = firebase.firestore();
        db.collection("JSON_Resumes/").doc(lastName + "_" + firstName + "-" + label).set(data);
    }

    async download() {
        const JSON_resume = this.JSON_resume;
        const filename = this.filenameDownload;
       
        const saveData = this.saveData;
        const templateRef = firebase.storage().ref("docx-templates/" + this.templateName);
        const templateURL = await templateRef.getDownloadURL();
        logFBEvent(JSON_resume);

        loadFile(templateURL, async function (error, template) {
            const zip = new PizZip(template);
            const formated_data = await getFormatedData(JSON_resume);
            const doc =  createDoc(zip, formated_data);
            const blob = await getBlob(doc);
            saveData(JSON_resume);
            downloadFile(blob, filename);
        });
    }

    async sendToEkkiden(emails=[]) {
        const JSON_resume = this.JSON_resume;
        const filename = this.filenameDownload;
        const filenameFireSave = this.filenameFireSave;
        const uploadToFirebase = this.uploadToFirebase;
        const bucket = "generated-resume";

        const saveData = this.saveData;
        const templateRef = firebase.storage().ref("docx-templates/" + this.templateName);
        const templateURL = await templateRef.getDownloadURL();
        logFBEvent(JSON_resume);

        loadFile(templateURL, async function (error, template) {
            const zip = new PizZip(template);
            const formated_data = getFormatedData(JSON_resume);
            const doc = createDoc(zip, formated_data);
            const blob = await getBlob(doc);
            const docURL = await uploadToFirebase(blob,bucket, filenameFireSave);
            saveData(JSON_resume);
            if (emails.length > 0){
                emails.forEach(email =>
                    sendEmail(email, docURL, JSON_resume.basics.name, filename)
                );
            }
        });
    }

    async setDocURL(setdocURL) {
        // TODO : Get the real link of the file in https
        const JSON_resume = this.JSON_resume;
        const templateRef = firebase.storage().ref("docx-templates/" + this.templateName);
        const templateURL = await templateRef.getDownloadURL();
        const filenameFireSave = this.filenameFireSave;
        const uploadToFirebase = this.uploadToFirebase;
        const bucket = "preview-resume";
        loadFile(templateURL, async function (error, template) {
            const zip = await new PizZip(template);
            const formated_data = await getFormatedData(JSON_resume);
            const doc =  createDoc(zip, formated_data);
            const blob = await getBlob(doc);
            const docURL =  await uploadToFirebase(blob,bucket, filenameFireSave);
            const url = encodeURIComponent(docURL);
            setdocURL(url);
        });
    }


}

export default WordDocument;

