import React from "react";
import Experience from "./Experience";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import nextId from "react-id-generator";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
	list: {
		padding: 0,
		margin:0
	},

	listItem: {
		paddingLeft: 1,
		paddingRight: 0,
		paddingTop: 1,
		paddingBottom: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(0),
	},
	dragIcon: {
		marginTop: theme.spacing(-2),
		marginRight: theme.spacing(-3),
	}
}));



export default function ExperienceForm({ work, setWork }) {
	const classes = useStyles();
	const {t} = useTranslation();

	const onDrop = ({ removedIndex, addedIndex }) => {
		const newExperiences = arrayMove(work, removedIndex, addedIndex)
		setWork(newExperiences);
	};


	const addExperience = () => {
		let nextEndDate = new Date("2017-09-01");
		if (work.length > 0) {
			const lastWork = work[work.length -1]
			nextEndDate =  new Date(lastWork.startDate.valueOf());
		}
		let nextStartDate = new Date(nextEndDate.valueOf())
		nextStartDate.setYear(nextEndDate.getFullYear()-2);

		setWork(
			[
				...work,
				{
					id: nextId("exp-id-"),
					company: "",
					position: "",
					area: "",
					city: "",
					country: "",
					startDate: nextStartDate,
					endDate: nextEndDate,
					context:"",
					summary: "",
					achievements:"",
					references: [{
						name : "",
						position: "",
						email: "",
						phone: ""            
					}],
					environment: []

				},
			]
		)
	};

	return (
		<Container>
			<Typography align='center' margin='2'>{t("PleaseOrderChnologically")}</Typography>
		<List className={classes.list}>
			<Container lockAxis="y" onDrop={onDrop} >
				{work.map((experience, index) => (
					<Draggable key={experience.id} >
						<ListItem className={classes.listItem}>
							<Experience work={work} setWork={setWork} index={index} />
							<IconButton disableRipple color="primary">
								<DragHandleIcon  color="primary" /> 
							</IconButton>
						</ListItem>
					</Draggable>
				))}
			</Container>

			<Button
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={addExperience}>
				{t("AddExperience")}
			</Button>
		</List>
		</Container>

	);
}

