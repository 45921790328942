import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from 'react-i18next';
import MuiPhoneInput from "material-ui-phone-number";
import MailOutlineIcon from '@material-ui/icons/MailOutlineOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import { FaAt } from "react-icons/fa";
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: theme.spacing(4),
		textAlign: "center",
		color: theme.palette.text.secondary,
		marginTop: theme.spacing(0),
		marginBottom: theme.spacing(2),
	},

	autocomplete: {
		marginTop: theme.spacing(2),
	}
}));

export default function HeaderForm({ basics, setBasics, appLanguage }) {

	const classes = useStyles();
	const { t } = useTranslation();

	const handlePersonChange = function (event) {
		const newValue = event.target.value;
		const attributeName = event.target.id
			? event.target.id
			: event.target.name;
		switch (attributeName) {
			case "address":
				basics["location"][attributeName] = newValue;
				break;

			case "firstName":
				basics[attributeName] = newValue;
				basics["name"] = newValue + " " + basics["lastname"];
				break;

			case "lastName":
				basics[attributeName] = newValue;
				basics["name"] = basics["firstName"] + " " + newValue;
				break;

			case "website":
				basics["profiles"] = [{
					"network": "website",
					"username": newValue,
					"url": newValue,
				}]
				break;

			default:
				basics[attributeName] = newValue;
				break;
		}
		setBasics(basics);
	}

	const handlePhoneChange = function (phone_str) {
		basics["phone"] = phone_str;
		setBasics(basics);
	}



	return (
		<React.Fragment>
			<Paper className={classes.paper} >
				<Typography component="h4" variant="h5" align="center">
					{t("JobTitle")}
				</Typography>

				<Grid container spacing={4}>
					<Grid item xs={12} sm={8}>
						<TextField
							id="label"
							name="label"
							required
							label={t("JobTitle")}
							helperText={t("IndicateYourRole")}
							defaultValue={basics.label}
							onChange={handlePersonChange}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={4}>

						<TextField
							id="yearsOfExperience"
							name="yearsOfExperience"
							label={t("YearsOfExperience")}
							maxLength={2}
							defaultValue={basics.yearsOfExperience}
							onChange={handlePersonChange}
							type="number"
							fullWidth
						/>
					</Grid>
				</Grid>
			</Paper>

			<Paper className={classes.paper}>
				<Typography component="h4" variant="h5" align="left">
					{t("ContactInformations")}
				</Typography>

				<Grid container spacing={4}>

					<Grid item xs={12} sm={6}>
						<TextField

							id="firstName"
							name="firstName"
							required
							label={t("FirstName")}
							defaultValue={basics.firstName}
							onChange={handlePersonChange}
							fullWidth
							autoComplete="given-name"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountBoxOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField

							id="lastName"
							name="lastName"
							required
							label={t("LastName")}
							defaultValue={basics.lastName}
							onChange={handlePersonChange}
							fullWidth
							autoComplete="family-name"
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField

							id="email"
							name="email"
							required
							label={t("Email")}
							defaultValue={basics.email}
							onChange={handlePersonChange}
							fullWidth
							autoComplete="email"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<MailOutlineIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>

						<MuiPhoneInput
							id="phone"
							name="phone"
							label={t("PhoneNumber")}
							value={basics.phone}
							fullWidth
							defaultCountry={appLanguage.code === "en" ? "fr" : appLanguage.code}
							regions={'europe'}
							preferredCountries={["it", "fr", "es", "de", "uk", "ch"]}
							onChange={handlePhoneChange}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField

							id="address"
							name="address"
							label={t("AddressComplete")}
							defaultValue={basics.location.address}
							onChange={handlePersonChange}
							fullWidth
							autoComplete="street-address"
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PlaceOutlinedIcon />
									</InputAdornment>
								),
							}}
						/>
					</Grid>

					<Grid item xs={12} sm={6}>
						<TextField

							id="website"
							name="website"
							label={t("ExternalLink")}
							defaultValue={basics.profiles[0].url}
							onChange={handlePersonChange}
							fullWidth
							autoComplete="url"
							helperText={t("GithubOrWebsite")}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<FaAt />
									</InputAdornment>
								),
							}}

						/>
					</Grid>


				</Grid>
			</Paper>

			<Paper className={classes.paper}>
				<Typography component="h4" variant="h5" align="left">
					{t("Summary")}
				</Typography>
				<TextField
					id="summary"
					name="summary"
					required
					label={t("Summary")}
					helperText={t("SummaryHelper")}
					multiline
					fullWidth
					margin="normal"
					onChange={handlePersonChange}
					defaultValue={basics.summary}
					rows="5"
					rowsMax="18"
					variant="outlined"
				/>
			</Paper>

		</React.Fragment>
	);
}



