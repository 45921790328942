import React, { useState } from "react";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTranslation } from 'react-i18next';
import nextId from "react-id-generator";
import Typography from "@material-ui/core/Typography";

export default function ImportDataButton({JSON_setters, openImportDataDialog, setOpenImportDataDialog, setShowSuccessDialog}) {
    const {t} = useTranslation();

    const [JSONInput, setJSONInput] = useState("");
    const [isJSON, setIsJSON] = useState(true);


    const handleClose = () => {
        setOpenImportDataDialog(false);
    };

    const isJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
    const handleUpload = () => {
        if (isJson(JSONInput)) {
            const JSON_resume = JSON.parse(JSONInput);
            loadJSONToHooks(JSON_resume);
            setOpenImportDataDialog(false);
            setShowSuccessDialog(true);

        } else {
            setIsJSON(false);
        }

    }

    const addIds = (array, idSuffix = "id-") => {
        return array.map(item => ({ ...item, id: nextId(idSuffix) }));;
    }

    const addProperty = (array, propName, value) => {
        return array.map(item => ({ ...item, [propName]: value }));;
    }

    const renameProperty = (array, propName, newPropName) => {
        return array.map(item => {
            const newObject = {};
            delete Object.assign(newObject, item, {[newPropName]: item[propName] })[propName];
            return newObject;
            }
            
        );
    }

    const castDate = (array, props) => {
        const copyArr = array.map(item => {
            props.forEach((date) => item[date] = new Date(item[date]))
            return item;
        });;

        return copyArr;
    }

    const loadJSONToHooks = (JSON_resume) => {
        let basics = JSON_resume.basics;
        if (basics !== undefined) {
            const nameSplit = basics.name.split(" ");
            basics.firstName = nameSplit[0];
            basics.lastName = nameSplit[1];
            console.log("Loading Basics ", basics);
            JSON_setters.setBasics(basics);
        }

        let education = JSON_resume.education;
        if (education !== undefined && education.length > 0) {
            education = addIds(education, "educ-id-")
            education = castDate(education, ["startDate", "endDate"]);
            console.log("Loading Education ", education);
            JSON_setters.setEducation(education);
        }

        let work = JSON_resume.work;
        if (work !== undefined && work.length > 0) {
            work = addIds(JSON_resume.work, "exp-id-");
            work = castDate(work, ["startDate", "endDate"]);
            work = addProperty(work, "environment", []);
            work = addProperty(work, "achievements", "");
            work = addProperty(work, "references", []);
            work = renameProperty(work, 'name', 'company');
            console.log("Loading work ", work);
            JSON_setters.setWork(work);
        }

        let languages = JSON_resume.languages;
        if (languages !== undefined && languages.length > 0){
            languages = languages.map(function (lang) {
                if(lang.language === 'en') lang.language = 'English';
                else if(lang.language === 'fr') lang.language = 'French';
                else if(lang.language === 'es') lang.language = 'Spanish';
                else if(lang.language === 'it') lang.language = 'Italian';
                else if(lang.language === 'de') lang.language = 'German';
                return lang;
            })
            console.log("Loading Languages ", languages);
            JSON_setters.setLanguage(languages);
        }

        let skills = JSON_resume.skills;
        if (skills !== undefined) {
            JSON_setters.setSkill({
                "Technical Skills": skills.map(skill => (skill.name)),
                "Functional Skills": [],
                "Business Sectors": [],
            });
            console.log("Loading Skills ", skills);

        }

        let awards = JSON_resume.awards;
        if (awards !== undefined && awards.length > 0) {
            awards = addIds(awards, "certif-id-");
            awards = castDate(awards, ["date"]);
            console.log("Loading awards ", awards);
            JSON_setters.setAward(awards);
        }
    }

    return (
        <Dialog open={openImportDataDialog} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">{t("UploadDataTitle")}</DialogTitle>
            <DialogContent>
                    <Typography>
                        {t("UploadDataInstruction.followStep")}
                    </Typography>
                    <Typography >
                        {t("UploadDataInstruction.1")} <a href="https://chrome.google.com/webstore/detail/json-resume-exporter/caobgmmcpklomkcckaenhjlokpmfbdec" target="_blank" rel="noopener noreferrer">Linkedin To JSON Resume</a>
                    </Typography>
                    <Typography>
                        {t("UploadDataInstruction.2")}<a href="https://www.linkedin.com/in/" target="_blank" rel="noopener noreferrer">Go to my profile</a>
                    </Typography>
                    <Typography>
                        {t("UploadDataInstruction.3")}
                    </Typography>
                    <Typography >
                        {t("UploadDataInstruction.4")}
                    </Typography>
                    <Typography >
                        {t("UploadDataInstruction.5")}
                    </Typography>
                <TextField
                    autoFocus
                    error={!isJSON}
                    helperText={(isJSON) ? null : t("MustBeJSON")}
                    variant="outlined"
                    multiline
                    id="json"
                    label={t("CopyPasteJSON")}
                    type="json"
                    rows="5"
                    fullWidth
                    onChange={(event) => setJSONInput(event.target.value)}
                />
                <img src={require("../img/demo-chrome_extension-JSONResume.gif")} alt="loading..." width={"100%"} />

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={handleUpload} color="primary" variant={"contained"}>
                    {t("Upload")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}