import React from "react";
import Education from "./Education";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import nextId from "react-id-generator";
import { Container, Draggable } from "react-smooth-dnd";
import arrayMove from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({

	list: {
		padding: 0,
		margin:0
	},

	listItem: {
		paddingLeft: 1,
		paddingRight: 0,
		paddingTop: 1,
		paddingBottom: theme.spacing(2),
	},

	button: {
		marginTop: theme.spacing(0),
	},

}));


export default function EducationForm({ education, setEducation }) {

	const classes = useStyles();
	const {t} = useTranslation();

	const onDrop = ({ removedIndex, addedIndex }) => {
		const neweducation = arrayMove(education, removedIndex, addedIndex)
		setEducation(neweducation);
	};


	const addeducation = () => {
		let nextEndDate = new Date("2017-09-01");
		if (education.length > 0) {
			const lastEdu = education[education.length -1]
			nextEndDate =  new Date(lastEdu.startDate.valueOf());
		}
		let nextStartDate = new Date(nextEndDate.valueOf())
		nextStartDate.setYear(nextEndDate.getFullYear()-2);

	
		setEducation([
			...education,
			{
				id: nextId("educ-id-"),
				institution: "",
				area: "",
				city: "",
				country: "",
				startDate: nextStartDate,
				endDate: nextEndDate,
				courses: [],
			},
		])
	};

	return (
		<Container>
			<Typography align='center' margin='2'>{t("PleaseOrderChnologically")}</Typography>

		<List className={classes.list}>
			<Container lockAxis="y" onDrop={onDrop} >
				{education.map((edu, index) => (
					<Draggable key={edu.id} >
						<ListItem className={classes.listItem}>
							<Education education={education} setEducation={setEducation} index={index} />
							<IconButton disableRipple color="primary"><DragHandleIcon   color="primary" /></IconButton>
						</ListItem>
					</Draggable>
				))}
			</Container>
			<Button
				className={classes.button}
				variant="contained"
				color="primary"
				onClick={addeducation}
			>
				{t("AddEducation")}
			</Button>
		</List>
		</Container>


	);
}

